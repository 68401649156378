import React, { useState } from 'react'
import { useRoute } from 'wouter'
import { useSpring } from '@react-spring/web'

import Minter from '../components/Minter/Minter'
import BackgroundImage from '../components/Blocks/BackgroundImage'
import useSpringStore from '../store/springStore'

export default function MintPage({ url }) {
	const [match] = useRoute(url)

	const [mounted, setMounted] = useState(match)
	const [mounting, setMounting] = useState(match)

	// Transition Animations: set mounted state on slower animation
	const config = useSpringStore((state) => state.transitionConfig)
	const transitionAnimation = useSpring({
		timeline: match ? 1 : 0,

		config: config,
	})

	const mountConfig = useSpringStore((state) => state.backgroundConfig)
	const mountAnimation = useSpring({
		timeline: match ? 1 : 0,
		onRest: (e) => {
			setMounting(false)
			if (e.value.timeline === 0) setMounted(false)
		},
		onStart: () => {
			setMounting(true)
			if (!mounted) setMounted(true)
		},
		config: mountConfig,
	})

	if (!mounted) return null

	return (
		<>
			<Minter animation={transitionAnimation} mounting={mounting} />
			<BackgroundImage animation={mountAnimation} />
		</>
	)
}
