import { useEffect } from 'react'

// Hook to be used on a global parent element
// Keeps the css variable --vw updated with the current screen size
export default function useDynamicVW() {
	useEffect(() => {
		let vw = window.innerWidth * 0.01
		document.documentElement.style.setProperty('--vw', `${vw}px`)
	}, [])

	useEffect(() => {
		onResize()
		window && window.addEventListener('resize', onResize)
		return () => {
			window && window.removeEventListener('resize', onResize)
		}
	}, [])

	const onResize = (e) => {
		let vw = window.innerWidth * 0.01
		document.documentElement.style.setProperty('--vw', `${vw}px`)
	}

	return null
}
