import React from 'react'
import { Web3ReactProvider } from '@web3-react/core'
import Web3 from 'web3'

import './App.scss'

import useDynamicVH from './helpers/useDynamicVH'
import useDynamicVW from './helpers/useDynamicVW'

import HomePage from './pages/HomePage'
import MintPage from './pages/MintPage'
import AboutPage from './pages/AboutPage'
import Menu from './components/Blocks/Menu'
import Meta from './helpers/Meta'

function App() {
	// Global hooks here
	// Keeps CSS variables --vh, --vw updated to screen height
	useDynamicVH()
	useDynamicVW()

	function getLibrary(provider) {
		return new Web3(provider)
	}

	return (
		<>
			<Meta />

			<Web3ReactProvider getLibrary={getLibrary}>
				<Menu />

				<AboutPage url="/about" />
				<HomePage url="/" />
				<MintPage url="/mint" />
			</Web3ReactProvider>
		</>
	)
}

export default App
