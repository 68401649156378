import React, { useEffect, useState } from 'react'
import { useSpring, animated as a } from '@react-spring/web'

import './FormInput.scss'
import Line from '../Blocks/Line'
import useSpringStore from '../../store/springStore'
import useBreakpoints from '../../helpers/useBreakpoints'
import { ReactComponent as MinusIcon } from '../../media/minus.svg'
import { ReactComponent as PlusIcon } from '../../media/plus.svg'

/* Form input component - used for text & number form inputs with spring based animation states */
export default function FormInput(props) {
	const [focused, setFocused] = useState(false)
	const [empty, setEmpty] = useState(true)

	const breakpoint = useBreakpoints()
	const focusConfig = useSpringStore((state) => state.hoverConfig)

	const focusAnimations = useSpring({
		timeline: focused || (!empty && !props.errors) ? 1 : 0,
		config: focusConfig,
		reset: true,
	})

	const errorAnimations = useSpring({
		timeline: props.errors !== undefined ? 1 : 0,
		config: focusConfig,
	})

	// Error message catching so that the message can fade in and out
	const [errorMessage, setErrorMessage] = useState()
	useEffect(() => {
		if (props.errors) {
			if (props.errors.message !== undefined) setErrorMessage(props.errors.message)
		}
	}, [props.errors])

	function incrementValue() {
		const currentValue = props.getValues(props.name)
		if (currentValue < props.mintLimit.max) props.setValue(props.name, Number(currentValue) + 1)
	}

	function decrementValue() {
		const currentValue = props.getValues(props.name)
		if (currentValue > props.mintLimit.min) props.setValue(props.name, Number(currentValue) - 1)
	}

	return (
		<div className="form-input-container">
			{props.inputType === 'number' ? (
				<div className="increment-button" onClick={() => decrementValue()}>
					<MinusIcon className="increment-button-icon" />
				</div>
			) : null}
			<input
				className="form-input-field"
				// register input into react-hook-form by invoking its "register" function
				{...props.registerParams.function(props.registerParams.value, props.validationParams)}
				// spread generic input params into input field
				{...props.inputParams}
				// handle input events for interactions
				onFocus={() => {
					setFocused(true)
				}}
				onBlur={(e) => {
					setFocused(false)

					if (e.target.value.length !== 0) {
						setEmpty(false)
					} else {
						setEmpty(true)
					}
				}}
			/>
			{props.inputType === 'number' ? (
				<div className="increment-button add" onClick={() => incrementValue()}>
					<PlusIcon className="increment-button-icon" />
				</div>
			) : null}

			<a.div
				className="subscribe-line-wrapper"
				style={{
					transformOrigin: 'left',
					opacity: focusAnimations.timeline
						.to({
							range: [0, 1],
							output: [0.75, 1],
						})
						.to((x) => `${x}`),
					transform: focusAnimations.timeline
						.to({
							range: [0, 1],
							output: [breakpoint === 'small' ? 1 : 0.75, 1],
						})
						.to((x) => `scaleX(${x})`),
				}}
			>
				<Line />
			</a.div>
			<a.div
				className="form-error-message-wrapper"
				style={{
					opacity: errorAnimations.timeline
						.to({
							range: [0, 1],
							output: [0, 1],
						})
						.to((x) => `${x}`),
				}}
			>
				<p className="form-error-message">{errorMessage}</p>
			</a.div>
		</div>
	)
}
