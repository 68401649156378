import React, { useState, useEffect, useCallback } from 'react'
import './Minter.scss'

import { useWeb3React } from '@web3-react/core'
import config from '../../config'

import { clamp } from '../../helpers/Maths'
import MinterForm from './MinterForm'
import Section from '../Utilities/Section'

export default function Minter(props) {
	const web3 = useWeb3React()

	const [mintState, setMintState] = useState()
	const [mintHash, setMintHash] = useState()

	const [contract, setContract] = useState(null)
	const [totalSupply, setTotalSupply] = useState(0)

	/* Fetch contract when web3 context is ready & eth wallet is connected */
	useEffect(() => {
		const fetchContract = () => {
			const contract = new web3.library.eth.Contract(
				config.web3.ABI,
				config.web3.CONTRACT_ADDRESS
			)
			setContract(contract)

			contract.methods
				.totalSupply()
				.call()
				.then((supply) => setTotalSupply(supply))
				.catch((err) => console.log(err))
		}

		if (web3.active) fetchContract()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [web3.active])

	/* Mint function - calls relevant web3 functions with parameters */
	const mint = useCallback(
		(quantity, eth, contract, contractAddress, nonce) => {
			// double check to clamp mint amounts
			quantity = clamp(quantity, config.web3.MINT_LIMIT.min, config.web3.MINT_LIMIT.max)

			// calculate total price
			const ethTotal = quantity * eth
			const price = web3.library.utils.toWei('' + ethTotal)

			// call mint function and encode ABI response
			const encoded = contract.methods.mint(quantity).encodeABI()

			// create transaction hash (tx)
			let tx = {
				from: web3.account,
				to: contractAddress,
				data: encoded,
				value: web3.library.utils.numberToHex(price),
			}

			// request eth to send transaction with transaction hash
			window.ethereum
				.request({ method: 'eth_sendTransaction', params: [tx] })
				.then((hash) => {
					setMintHash(hash)
					setMintState('SUCCESS')
				})
				.catch((e) => {
					setMintState('REJECTED')
				})
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[web3.account]
	)

	/* Pre-Mint error & case handling */
	const prepareMint = useCallback(
		(runMintFunction) => {
			if (web3.account) {
				runMintFunction()
			}
			// else if (window.ethereum) {
			// 	alert('connect wallet before minting')
			// } else {
			// 	alert('error:\nno web3 wallet detected.\nplease install metamask.')
			// }
		},
		[web3.account]
	)

	/* trigger mint case handling and final mint function */
	const mintNFT = useCallback(
		(quantity) => {
			prepareMint(() =>
				mint(
					quantity,
					config.web3.MINT_PRICE_ETH,
					contract,
					config.web3.CONTRACT_ADDRESS,
					undefined
				)
			)
		},
		[contract, mint, prepareMint]
	)

	return (
		<Section fullheight center mounting={props.mounting} animation={props.animation}>
			<div className="minter-form-wrapper">
				<MinterForm
					mintFunction={mintNFT}
					totalSupply={totalSupply}
					maxSupply={config.web3.MAX_SUPPLY}
					mintLimit={config.web3.MINT_LIMIT}
					mintPrice={config.web3.MINT_PRICE_ETH}
					mintState={mintState}
					mintHash={mintHash}
				/>
			</div>
		</Section>
	)
}
