import { useEffect } from 'react'

// Hook to be used on a global parent element
// Keeps the css variable --vh updated with the current screen size
export default function useDynamicVH() {
	useEffect(() => {
		let vh = window.innerHeight * 0.01
		document.documentElement.style.setProperty('--vh', `${vh}px`)
	}, [])

	useEffect(() => {
		onResize()
		window && window.addEventListener('resize', onResize)
		return () => {
			window && window.removeEventListener('resize', onResize)
		}
	}, [])

	const onResize = (e) => {
		let vh = window.innerHeight * 0.01
		document.documentElement.style.setProperty('--vh', `${vh}px`)
	}

	return null
}
