import { useState, useEffect } from 'react'
import useWindowSize from './useWindowSize'

// Returns "small", "medium" or "large" based on breakpoints from _variables.scss
export default function useBreakpoints() {
	const windowSize = useWindowSize()
	const [breakpoint, setBreakpoint] = useState({
		small: false,
		medium: false,
		large: false,
	})

	const breakpointValues = {
		small: 845,
		medium: 846,
		large: 1080,
	}

	useEffect(() => {
		if (windowSize.width <= breakpointValues.small) {
			setBreakpoint(() => {
				return { small: true, medium: false, large: false }
			})
		} else if (
			windowSize.width > breakpointValues.medium &&
			windowSize.width < breakpointValues.large
		) {
			setBreakpoint(() => {
				return { small: false, medium: true, large: false }
			})
		} else {
			setBreakpoint(() => {
				return { small: false, medium: false, large: true }
			})
		}
	}, [breakpointValues.large, breakpointValues.medium, breakpointValues.small, windowSize.width])

	return breakpoint
}
