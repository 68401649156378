import Head from 'next/head'

const Meta = () => {
	const TITLE = 'EMBRYONIC | Generative On-Chain NFTs'
	const DESCRIPTION = 'Generative On-Chain NFTs'
	const URL = 'http://localhost:3001'
	const TWITTER_AUTHOR = '@artdothaus'

	return (
		<Head>
			<title></title>
			<meta name="description" content={DESCRIPTION} />
			<meta property="og:type" content="website" />
			<meta name="og:title" property="og:title" content={TITLE} />
			<meta name="og:description" property="og:description" content={DESCRIPTION} />
			<meta property="og:site_name" content={TITLE} />
			<meta property="og:url" content={URL} />
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:title" content={TITLE} />
			<meta name="twitter:description" content={DESCRIPTION} />
			<meta name="twitter:site" content={URL} />
			<meta name="twitter:creator" content={TWITTER_AUTHOR} />
			<link rel="icon" type="image/png" href="/public/favicon.jpg" />
			<link rel="apple-touch-icon" href="/public/favicon.jpg" />
			<meta property="og:image" content="/public/favicon.jpg" />
			<meta name="twitter:image" content="/public/favicon.jpg" />
		</Head>
	)
}

export default Meta
