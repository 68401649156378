import { useSpring } from '@react-spring/web'
import React, { useState } from 'react'
import { useRoute } from 'wouter'

import About from '../components/Sections/About'
import useSpringStore from '../store/springStore'

export default function AboutPage({ url }) {
	const [match] = useRoute(url)

	const [mounted, setMounted] = useState(match)
	const [mounting, setMounting] = useState(match)

	const config = useSpringStore((state) => state.transitionConfig)
	const transitionAnimation = useSpring({
		timeline: match ? 1 : 0,

		onRest: (e) => {
			setMounting(false)
			if (e.value.timeline === 0) setMounted(false)
		},
		onStart: () => {
			setMounting(true)
			if (!mounted) setMounted(true)
		},
		config: config,
	})

	if (!mounted) return null
	return (
		<>
			<About animation={transitionAnimation} mounting={mounting} />
		</>
	)
}
