import React, { useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { injected } from '../../web3/connectors'

import './Connector.scss'

export default function Connector(props) {
	const { activate } = useWeb3React()

	/* Automatically connect wallet & start web3 library if it was setup on this window previously */
	useEffect(() => {
		if (window.ethereum) activate(injected)
	}, [activate])

	async function connect() {
		try {
			await activate(injected)
		} catch (ex) {
			console.log(ex)
		}
	}

	return (
		<button type="button" onClick={connect}>
			Connect Metamask
		</button>
	)
}
