import React from 'react'
import { animated as a } from '@react-spring/web'

import './Section.scss'

export default function Section(props) {
	return (
		<a.div
			className={`section 
			${props.fullheight ? 'full-height' : ''} 
			${props.center ? 'center' : ''} 
			${props.mounting ? 'mounting' : ''}
			${props.white ? 'white' : ''}`}
			style={{
				opacity: props.animation
					? props.animation.timeline
							.to({
								range: [0, 1],
								output: [0, 1],
							})
							.to((x) => `${x}`)
					: null,
			}}
		>
			<div className="container">{props.children}</div>
		</a.div>
	)
}
