import React from 'react'

import './Menu.scss'
import { Link, useRoute } from 'wouter'
import { useInView } from 'react-intersection-observer'
import { useSpring, animated as a } from '@react-spring/web'
import useSpringStore from '../../store/springStore'

export default function Menu() {
	const [inViewDetector, inView] = useInView({ triggerOnce: true })

	const config = useSpringStore((state) => state.revealConfig)

	const logoAnimation = useSpring({
		opacity: inView ? 1 : 0,
		config: config,
		delay: 100,
	})

	const menuButtonAnimation = useSpring({
		opacity: inView ? 1 : 0,
		config: config,
		delay: 300,
	})

	const [isHome] = useRoute('/')
	const [isAbout] = useRoute('/about')
	const [isMint] = useRoute('/mint')

	return (
		<div className={`menu-wrapper  ${isHome ? 'white' : ''}`} ref={inViewDetector}>
			<a.div className="menu-buttons-wrapper" style={menuButtonAnimation}>
				<Link to={`/about`}>
					<div className="menu-button left">
						<p className={`menu-button-text${isAbout ? ' active' : ''}`}>About</p>
					</div>
				</Link>
				<Link to={`/mint`}>
					<div className="menu-button right">
						<p className={`menu-button-text${isMint ? ' active' : ''}`}>Mint</p>
					</div>
				</Link>
			</a.div>

			<a.div className="menu-logo-wrapper" style={logoAnimation}>
				<Link className="menu-logo" to={`/`}>
					<h1>EMBRYONIC</h1>
				</Link>
			</a.div>
		</div>
	)
}
