import { useWeb3React } from '@web3-react/core'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Connector from './Connector'
import FormInput from './FormInput'
import './MinterForm.scss'

export default function MinterForm(props) {
	const web3 = useWeb3React()

	const {
		register,
		handleSubmit,
		getValues,
		setValue,
		formState: { errors, isValid, isSubmitting, isSubmitSuccessful },
	} = useForm({
		mode: 'onChange', // Validation when input values are changed
	})

	const [stateText, setStateText] = useState(null)

	// Submitted state indicators
	useEffect(() => {
		if (props.mintState === 'REJECTED') {
			setStateText('Mint Request Rejected')
		} else if (isSubmitSuccessful && props.mintState === 'SUCCESS') {
			setStateText('Minted.')

			setTimeout(() => {
				setStateText('Mint')
			}, 5000)
		} else if ((isSubmitting || isSubmitSuccessful) && isValid) {
			setStateText('Minting...')
		} else {
			setStateText('Mint')
		}
	}, [isSubmitSuccessful, isSubmitting, isValid, props.mintHash, props.mintState])

	const onSubmit = (data) => {
		props.mintFunction(data.mintQuantity)
	}

	return (
		<form className="minter-form" onSubmit={handleSubmit(onSubmit)}>
			<div className="minter-form-line">
				<h3>Remaining</h3>
				<p>
					{web3.active ? props.maxSupply - props.totalSupply : '?'} / {props.maxSupply}
				</p>
			</div>
			<div className="minter-form-line">
				<h3>Price</h3>
				<p>{props.mintPrice + ' Ξ'}</p>
			</div>
			<div className="minter-form-line center">
				<h3 className="minter-form-field-label">
					Mint Quantity {props.mintLimit.min} - {props.mintLimit.max}
				</h3>
			</div>
			<FormInput
				name="mintQuantity"
				inputType="number"
				// Generic input params go here
				inputParams={{
					autoFocus: true,
					type: 'text',
					defaultValue: '1',
					readOnly: true,
				}}
				// react-hook-form's register params go here
				registerParams={{ function: register, value: 'mintQuantity' }}
				// react-hook-form's validation params go here
				validationParams={{
					required: 'Field Required',
					pattern: {
						value: /^[1-9][0-9]*$/i, // non 0 valid integers
						message: 'Must Be Valid Number',
					},
					min: {
						value: props.mintLimit.min,
						message: 'Must be between range',
					},
					max: {
						value: props.mintLimit.max,
						message: 'Must be between range',
					},
				}}
				errors={errors.mintQuantity}
				getValues={getValues}
				setValue={setValue}
				mintLimit={props.mintLimit}
			/>

			<div className="minter-form-buttons-wrapper">
				<button type="submit" style={{ display: web3.active ? 'inline-block' : 'none' }}>
					{stateText}
				</button>
				{!web3.active ? <Connector /> : null}
			</div>
			{isSubmitSuccessful && props.mintState === 'SUCCESS' ? (
				<div className="minter-form-line center">
					<h3 className="minter-form-field-label">
						Minted To: {String(props.mintHash).slice(0, 6)}...
					</h3>
				</div>
			) : null}
		</form>
	)
}
