// Production Variables - set to the testing contracts
const development = {
	web3: {
		WEB3_PROVIDER: 'https://mainnet.infura.io/v3/d9402aa64bcb45d795fa5cc95e1b0dae',
		CONTRACT_ADDRESS: '0xD878F0b6E99C6d5ACCCa96133bc4BDc3EF665692',
		MINT_LIMIT: { min: 1, max: 7 },
		MAX_SUPPLY: 1000,
		MINT_PRICE_ETH: 0.007,
		ABI: [
			{ inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
			{
				anonymous: false,
				inputs: [
					{ indexed: true, internalType: 'address', name: 'owner', type: 'address' },
					{ indexed: true, internalType: 'address', name: 'approved', type: 'address' },
					{ indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
				],
				name: 'Approval',
				type: 'event',
			},
			{
				anonymous: false,
				inputs: [
					{ indexed: true, internalType: 'address', name: 'owner', type: 'address' },
					{ indexed: true, internalType: 'address', name: 'operator', type: 'address' },
					{ indexed: false, internalType: 'bool', name: 'approved', type: 'bool' },
				],
				name: 'ApprovalForAll',
				type: 'event',
			},
			{
				anonymous: false,
				inputs: [
					{ indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
					{ indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
				],
				name: 'OwnershipTransferred',
				type: 'event',
			},
			{
				anonymous: false,
				inputs: [
					{ indexed: true, internalType: 'address', name: 'from', type: 'address' },
					{ indexed: true, internalType: 'address', name: 'to', type: 'address' },
					{ indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
				],
				name: 'Transfer',
				type: 'event',
			},
			{
				inputs: [
					{ internalType: 'address', name: 'to', type: 'address' },
					{ internalType: 'uint256', name: 'tokenId', type: 'uint256' },
				],
				name: 'approve',
				outputs: [],
				stateMutability: 'nonpayable',
				type: 'function',
			},
			{
				inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
				name: 'balanceOf',
				outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
				name: 'getApproved',
				outputs: [{ internalType: 'address', name: '', type: 'address' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [
					{ internalType: 'address', name: 'owner', type: 'address' },
					{ internalType: 'address', name: 'operator', type: 'address' },
				],
				name: 'isApprovedForAll',
				outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [],
				name: 'maximumMintQuantity',
				outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [{ internalType: 'uint256', name: 'quantity', type: 'uint256' }],
				name: 'mint',
				outputs: [],
				stateMutability: 'payable',
				type: 'function',
			},
			{
				inputs: [],
				name: 'name',
				outputs: [{ internalType: 'string', name: '', type: 'string' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [],
				name: 'owner',
				outputs: [{ internalType: 'address', name: '', type: 'address' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
				name: 'ownerOf',
				outputs: [{ internalType: 'address', name: '', type: 'address' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [],
				name: 'price',
				outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [],
				name: 'renounceOwnership',
				outputs: [],
				stateMutability: 'nonpayable',
				type: 'function',
			},
			{
				inputs: [
					{ internalType: 'address', name: 'from', type: 'address' },
					{ internalType: 'address', name: 'to', type: 'address' },
					{ internalType: 'uint256', name: 'tokenId', type: 'uint256' },
				],
				name: 'safeTransferFrom',
				outputs: [],
				stateMutability: 'nonpayable',
				type: 'function',
			},
			{
				inputs: [
					{ internalType: 'address', name: 'from', type: 'address' },
					{ internalType: 'address', name: 'to', type: 'address' },
					{ internalType: 'uint256', name: 'tokenId', type: 'uint256' },
					{ internalType: 'bytes', name: '_data', type: 'bytes' },
				],
				name: 'safeTransferFrom',
				outputs: [],
				stateMutability: 'nonpayable',
				type: 'function',
			},
			{
				inputs: [
					{ internalType: 'address', name: 'operator', type: 'address' },
					{ internalType: 'bool', name: 'approved', type: 'bool' },
				],
				name: 'setApprovalForAll',
				outputs: [],
				stateMutability: 'nonpayable',
				type: 'function',
			},
			{
				inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
				name: 'supportsInterface',
				outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [],
				name: 'symbol',
				outputs: [{ internalType: 'string', name: '', type: 'string' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [{ internalType: 'uint256', name: 'index', type: 'uint256' }],
				name: 'tokenByIndex',
				outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [
					{ internalType: 'address', name: 'owner', type: 'address' },
					{ internalType: 'uint256', name: 'index', type: 'uint256' },
				],
				name: 'tokenOfOwnerByIndex',
				outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
				name: 'tokenURI',
				outputs: [{ internalType: 'string', name: '', type: 'string' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [],
				name: 'totalSupply',
				outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [
					{ internalType: 'address', name: 'from', type: 'address' },
					{ internalType: 'address', name: 'to', type: 'address' },
					{ internalType: 'uint256', name: 'tokenId', type: 'uint256' },
				],
				name: 'transferFrom',
				outputs: [],
				stateMutability: 'nonpayable',
				type: 'function',
			},
			{
				inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
				name: 'transferOwnership',
				outputs: [],
				stateMutability: 'nonpayable',
				type: 'function',
			},
			{
				inputs: [],
				name: 'withdrawAll',
				outputs: [],
				stateMutability: 'payable',
				type: 'function',
			},
		],
	},
}

// Production Variables - set to the real contracts
const production = {
	web3: {
		WEB3_PROVIDER: 'https://mainnet.infura.io/v3/d9402aa64bcb45d795fa5cc95e1b0dae',
		CONTRACT_ADDRESS: '0xfF07F31678E873C06938f17452Cb99eD58A97E5d',
		MINT_LIMIT: { min: 1, max: 42 },
		MAX_SUPPLY: 4242,
		MINT_PRICE_ETH: 0.042,
		ABI: [
			{ inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
			{
				anonymous: false,
				inputs: [
					{ indexed: true, internalType: 'address', name: 'owner', type: 'address' },
					{ indexed: true, internalType: 'address', name: 'approved', type: 'address' },
					{ indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
				],
				name: 'Approval',
				type: 'event',
			},
			{
				anonymous: false,
				inputs: [
					{ indexed: true, internalType: 'address', name: 'owner', type: 'address' },
					{ indexed: true, internalType: 'address', name: 'operator', type: 'address' },
					{ indexed: false, internalType: 'bool', name: 'approved', type: 'bool' },
				],
				name: 'ApprovalForAll',
				type: 'event',
			},
			{
				anonymous: false,
				inputs: [
					{ indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
					{ indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
				],
				name: 'OwnershipTransferred',
				type: 'event',
			},
			{
				anonymous: false,
				inputs: [
					{ indexed: true, internalType: 'address', name: 'from', type: 'address' },
					{ indexed: true, internalType: 'address', name: 'to', type: 'address' },
					{ indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
				],
				name: 'Transfer',
				type: 'event',
			},
			{
				inputs: [
					{ internalType: 'address', name: 'to', type: 'address' },
					{ internalType: 'uint256', name: 'tokenId', type: 'uint256' },
				],
				name: 'approve',
				outputs: [],
				stateMutability: 'nonpayable',
				type: 'function',
			},
			{
				inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
				name: 'balanceOf',
				outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
				name: 'getApproved',
				outputs: [{ internalType: 'address', name: '', type: 'address' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [
					{ internalType: 'address', name: 'owner', type: 'address' },
					{ internalType: 'address', name: 'operator', type: 'address' },
				],
				name: 'isApprovedForAll',
				outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [],
				name: 'maximumMintQuantity',
				outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [{ internalType: 'uint256', name: 'quantity', type: 'uint256' }],
				name: 'mint',
				outputs: [],
				stateMutability: 'payable',
				type: 'function',
			},
			{
				inputs: [],
				name: 'name',
				outputs: [{ internalType: 'string', name: '', type: 'string' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [],
				name: 'owner',
				outputs: [{ internalType: 'address', name: '', type: 'address' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
				name: 'ownerOf',
				outputs: [{ internalType: 'address', name: '', type: 'address' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [],
				name: 'price',
				outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [],
				name: 'renounceOwnership',
				outputs: [],
				stateMutability: 'nonpayable',
				type: 'function',
			},
			{
				inputs: [
					{ internalType: 'address', name: 'from', type: 'address' },
					{ internalType: 'address', name: 'to', type: 'address' },
					{ internalType: 'uint256', name: 'tokenId', type: 'uint256' },
				],
				name: 'safeTransferFrom',
				outputs: [],
				stateMutability: 'nonpayable',
				type: 'function',
			},
			{
				inputs: [
					{ internalType: 'address', name: 'from', type: 'address' },
					{ internalType: 'address', name: 'to', type: 'address' },
					{ internalType: 'uint256', name: 'tokenId', type: 'uint256' },
					{ internalType: 'bytes', name: '_data', type: 'bytes' },
				],
				name: 'safeTransferFrom',
				outputs: [],
				stateMutability: 'nonpayable',
				type: 'function',
			},
			{
				inputs: [
					{ internalType: 'address', name: 'operator', type: 'address' },
					{ internalType: 'bool', name: 'approved', type: 'bool' },
				],
				name: 'setApprovalForAll',
				outputs: [],
				stateMutability: 'nonpayable',
				type: 'function',
			},
			{
				inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
				name: 'supportsInterface',
				outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [],
				name: 'symbol',
				outputs: [{ internalType: 'string', name: '', type: 'string' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [{ internalType: 'uint256', name: 'index', type: 'uint256' }],
				name: 'tokenByIndex',
				outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [
					{ internalType: 'address', name: 'owner', type: 'address' },
					{ internalType: 'uint256', name: 'index', type: 'uint256' },
				],
				name: 'tokenOfOwnerByIndex',
				outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
				name: 'tokenURI',
				outputs: [{ internalType: 'string', name: '', type: 'string' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [],
				name: 'totalSupply',
				outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
				stateMutability: 'view',
				type: 'function',
			},
			{
				inputs: [
					{ internalType: 'address', name: 'from', type: 'address' },
					{ internalType: 'address', name: 'to', type: 'address' },
					{ internalType: 'uint256', name: 'tokenId', type: 'uint256' },
				],
				name: 'transferFrom',
				outputs: [],
				stateMutability: 'nonpayable',
				type: 'function',
			},
			{
				inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
				name: 'transferOwnership',
				outputs: [],
				stateMutability: 'nonpayable',
				type: 'function',
			},
			{
				inputs: [],
				name: 'withdrawAll',
				outputs: [],
				stateMutability: 'payable',
				type: 'function',
			},
		],
	},
}

const config = process.env.NODE_ENV === 'production' ? production : development

export default config
