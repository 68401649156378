import React from 'react'
import { animated as a } from '@react-spring/web'

import './BackgroundImage.scss'

export default function BackgroundImage(props) {
	return (
		<div className="background-image-wrapper">
			<a.img
				className="background-image"
				src={
					'data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA4MDAgODAwJyBzdHlsZT0nYmFja2dyb3VuZDogcmFkaWFsLWdyYWRpZW50KGNpcmNsZSwgaHNsYSg2MSwgOTElLCA4NyUsIDEpIDAlLCBoc2xhKDMxLCA5OCUsIDc2JSwgMSkgMTIuNSUsIGhzbGEoMTUsIDk1JSwgNjglLCAxKSAyNSUsIGhzbGEoMzUzLCA3NSUsIDYxJSwgMSkgMzcuNSUsIGhzbGEoMzI5LCA1NCUsIDQ2JSwgMSkgNTAlLCBoc2xhKDMwMSwgNTYlLCAzMyUsIDEpIDYyLjUlLCBoc2xhKDI3NSwgNzQlLCAyOCUsIDEpIDc1JSwgaHNsYSgyNTMsIDYzJSwgMTclLCAxKSA4Ny41JSwgaHNsYSgyNDAsIDEwMCUsIDElLCAxKSAxMDAlKTsnPjxmaWx0ZXIgaWQ9J2EnPjxmZVR1cmJ1bGVuY2UgYmFzZUZyZXF1ZW5jeT0nMC4wMDQnIHNlZWQ9JzY0NjInLz48L2ZpbHRlcj48cmVjdCB3aWR0aD0nMTAwJScgaGVpZ2h0PScxMDAlJyBmaWx0ZXI9J3VybCgjYSknLz48L3N2Zz4='
				}
				alt=""
				style={{
					transformOrigin: 'center',
					transform: props.animation.timeline
						.to({
							range: [0, 1],
							output: [100, 200],
						})
						.to((x) => `scale(${x}%)`),

					opacity: props.animation.timeline
						.to({
							range: [0, 1],
							output: [0, 1],
						})
						.to((x) => `${x}`),
				}}
			/>
		</div>
	)
}
