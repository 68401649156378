import React from 'react'
import './About.scss'

import Section from '../Utilities/Section'

export default function About(props) {
	return (
		<Section fullheight center mounting={props.mounting} animation={props.animation}>
			<div className="about-wrapper">
				<div className="about-block">
					<h2>Generative</h2>
					<p>
						Embryonic is an installation of 4242 generative works, constructed and stored
						entirely on-chain, as infinitely scalable vector graphics on Ethereum. Each
						piece represents an embryo-like formation exploiting the feTurbulence SVG filter
						through its seed and base frequency attributes. The randomly generated input is
						referred to as the zygote whose frequency is measured in hertz (Hz).
					</p>
				</div>

				<div className="about-block">
					<h2>Fully On-Chain</h2>
					<p>
						Embryos are generated by a smart contract as part of each minting transaction
						and have varying rarities, with zygotes ranging from 1 to 9999 at frequencies of
						2 to 5 Hz. The artworks are stored within each token's metadata as a base64
						encoded data URI, with the metadata itself also stored within the NFT in the
						same way. Everything required to retrieve the metadata and image for each NFT
						can be queried from the Token URI directly from the smart contract on the
						Ethereum blockchain using the read functionality on Etherscan and other block
						explorers.
					</p>
				</div>

				<div className="about-block">
					<h2>Immutable</h2>
					<p>
						It's important that these embryonic creations live on for as long as Ethereum
						with no external dependency on other infrastructures such as IPFS hosting. Thank
						you for your patronage, Arthur Hausen.
					</p>
				</div>
			</div>
		</Section>
	)
}
